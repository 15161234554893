import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { ThemeContext } from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import { useQueryParam, StringParam } from 'use-query-params'

import Head from 'components/Head'
import Header from 'components/PageHeader'
import Grid from 'components/collection/Grid'

import Fade from 'transitions/Fade'

const Search = ({
  data: { localSearchProducts }
}) => {
  const [query] = useQueryParam('q', StringParam)
  const { index, store } = localSearchProducts
  
  const products = useFlexSearch(query, index, store)  

  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.global.setHeaderPadding(false)    
  }, [theme.global])
  
  return (
    <Fade>
    <Head title={'Search'} />
    <Header
        title={`Search results for "${query}"`}
        description={`${products.length} result${products.length !== 1 ? 's' : ''}`}
        layout='Hero'
        noMargin />
    <Grid products={products} />
    </Fade>
  )
}

export const query = graphql`
  query {
    localSearchProducts {
      index
      store
    }
  }
`

export default Search
